import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout/peo';
import PEOTemplate from '../../templates/pages/peo';

const IndexPage = ( { data } ) => {
	const {
		file: {
			childMarkdownRemark: {
				frontmatter: {
					description,
					keywords,
					landingBanner: { headings },
					pageBody
				}
			}
		}
	} = data;

	useEffect( () => {
		new WOW()
			.init();
	}, [] );

	return (
		<Layout>
			<Helmet>
				<link rel="stylesheet" href="../assets/css/bootstrap.min.css" />
				<link rel="stylesheet" href="https://kinetic-styles.netlify.app/peoStyles.css" />
				<meta name="description" content={ description }/>
				<meta name="keywords" content={ keywords }/>
				<meta name="author" content="Kinetic IQ Team"/>
				<script src="../assets/js/bootstrap.min.js"></script>
				<script src="../assets/js/wow.js"></script>
			</Helmet>
			<PEOTemplate
				headings={headings}
				pageBody={pageBody}
				description={ description }
				keywords ={ keywords }
			/>

		</Layout>
	);
};

IndexPage.propTypes = { data: PropTypes.object };

export default IndexPage;

export const query = graphql`
	query PEO {
		file(relativePath: {eq: "pages/peo.md"}) {
			childMarkdownRemark {
				frontmatter {
				description
				keywords
					landingBanner {
						headings {
							heading
							subHeading
						}
					}
					pageBody {
						type
						htmlInput
						features {
							moduleId
							heading
							variant
							img {
								childImageSharp {
									fluid(maxWidth: 1400, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
								extension
								publicURL
							}
							croppedImg
							imgBorder
							backgroundColor
							backgroundImg
							textColor
							headerColor
							border
							copy {
								type
								text
								dropDownItem {
									title
									heading
									copy
								}
								button {
									type
									link
									buttonText
									variant
								}
							}
						}
						bulletLists {
							moduleId
							backgroundColor
							textColor
							header {
								title
								titleColor
								copy {
									text
									width
								}
							}
							bulletPoints {
								bullet {
									heading
									copy
								}
							}
						}
						cardGroup {
							heading
							headerColor
							cardsLayout
							card {
								type
								moduleId
								nav {
									dynamicLink
									staticLink
								}
								heading
								subHeading
								icon {
									childImageSharp {
										fluid(maxWidth: 1400, quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
									extension
									publicURL
								}
								img {
									childImageSharp {
										fluid(maxWidth: 1400, quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
									extension
									publicURL
								}
								navCopy
								featureCopy {
									type
									text
									buttons {
										type
										link
										buttonText
										variant
									}
								}
							}
						}
						featureCarousels {
							moduleId
							heading
							variant
							img {
								childImageSharp {
									fluid(maxWidth: 1400, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
							croppedImg
							imgBorder
							backgroundColor
							backgroundImg
							textColor
							headerColor
							border
							copy {
								type
								text
								dropDownItem {
									title
									heading
									copy
								}
								button {
									type
									link
									buttonText
									variant
								}
							}
						}
						banner {
							moduleId
							heading
							copy
							button {
								type
								link
								buttonText
								variant
							}
							backgroundColor
							backgroundImg {
								childImageSharp {
									fluid(maxWidth: 1400, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
								extension
								publicURL
							}
							headerColor
							textColor
							border
						}
					}
				}
			}
		}
	}
	`;
