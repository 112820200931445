import { useStaticQuery, graphql } from 'gatsby';

const useCompanyInfo = () => {
	const { file: { childMarkdownRemark: { frontmatter: data } } } = useStaticQuery( graphql`
			query CompanyInfo {
				file(relativePath: {eq: "company-info.md"}) {
					childMarkdownRemark {
						frontmatter {
							companyName
							address
							address2
							csz
							phone
							email
							headerLogo {
								childImageSharp {
									gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
								}
							}
							navigation {
								desktop
								footer
								mobile
								# associatedPageGroups {
								# 	category {
								# 		pageName
								# 		associatedPages
								# 	}
								# }
							}
						}
					}
				}
			}
		` );

	return data;
};

export default useCompanyInfo;
